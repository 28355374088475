import * as React from "react";
import Img from "./image";

export interface AboutItemProps {
  className: string;
  name: string;
  text: string;
  image: string;
  imgClassName: string;
}

export interface AboutItemState {
  rotate: boolean;
}

class AboutItem extends React.Component<AboutItemProps, AboutItemState> {
  state = {
    rotate: false
  };

  textRotate = (e: React.SyntheticEvent) => {
    e.preventDefault();
    this.setState({
      rotate: true
    });
  };

  onBlur = (e: React.SyntheticEvent) => {
    e.preventDefault();
    this.setState({
      rotate: false
    });
  };

  render() {
    const { className, name, text, image, children, imgClassName } = this.props;
    return (
      <>
        <div
          className={`about-${className} about-img`}
          onBlur={this.onBlur}
          tabIndex={1}
        >
          <div className="img-content">
            <div className={this.state.rotate ? "rotate" : ""}>
              {this.state.rotate ? (
                <span>{text}</span>
              ) : (
                <h2 dangerouslySetInnerHTML={{ __html: name }} />
              )}
            </div>
          </div>
          <div className={imgClassName}>
            <Img image={image} />
          </div>
          {this.state.rotate ? null : (
            <a onClick={this.textRotate}>
              <i className="icon-down-arrow" />
            </a>
          )}
        </div>
        {children ? <div className="flex-between">{children}</div> : null}
      </>
    );
  }
}

export default AboutItem;
