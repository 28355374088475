import * as React from "react";
import Img from "./image";
import "../styles/products.css";

import { TranslationConsumer } from "../context/TranslationContext";

export interface Product1Props {}

export interface Product1State {}

class Product1 extends React.Component<Product1Props, Product1State> {
  state = {};

  navigateTo = (e: React.SyntheticEvent<HTMLAnchorElement>) => {
    const { href } = e.currentTarget;
    e.preventDefault();
    window.scroll({
      top:
        (document.querySelector(
          href.slice(href.lastIndexOf("/") + 1)
        ) as HTMLElement).offsetTop - 88,
      behavior: "smooth"
    });
  };

  render() {
    return (
      
      <section className="container product-main " id="machinery">
        <TranslationConsumer>
          {(context: any) => {
            return (
              <>
                <div className="product-item-1 flex-between">
                  <div>
                    <Img image="image1" />
                  </div>

                  <div>
                    <h2>{context.translate("product1.productLine")}</h2>
                    <span>
                      {context.translate("product1.productLineText")}
                      <a href="#item2" onClick={this.navigateTo}>
                        <i className="icon-down-arrow" />
                      </a>
                    </span>
                  </div>
                </div>
                <div className="product-item-2 flex-between" id="item2">
                  <div>
                    <h2>{context.translate("product1.machinery")}</h2>
                    <span>
                      {context.translate("product1.machineryText")}
                      <a href="#mission" onClick={this.navigateTo}>
                        <i
                          style={{ textAlign: "right" }}
                          className="icon-down-arrow"
                        />
                      </a>
                    </span>
                  </div>
                  <div>
                    <Img image="image2" />
                  </div>
                </div>
              </>
            );
          }}
        </TranslationConsumer>
      </section>
    );
  }
}

export default Product1;
