import * as React from "react";

import Img from "./image";
import { TranslationConsumer } from "../context/TranslationContext";

export interface Product2Props {}

export interface Product2State {}

class Product2 extends React.Component<Product2Props, Product2State> {
  state = {};

  navigateTo = (e: React.SyntheticEvent<HTMLAnchorElement>) => {
    const { href } = e.currentTarget;
    e.preventDefault();
    window.scroll({
      top:
        (document.querySelector(
          href.slice(href.lastIndexOf("/") + 1)
        ) as HTMLElement).offsetTop - 88,
      behavior: "smooth"
    });
  };

  render() {
    return (
      <section className="product-main container">
        <div className="product-item-2 flex-between">
          <TranslationConsumer>
            {(context: any) => {
              return (
                <div>
                  <h2>{context.translate("product2.material")}</h2>
                  <span>{context.translate("product2.materialText")}</span>
                  <a href="#cycle" onClick={this.navigateTo}>
                    <i
                      style={{
                        textAlign: "right",
                        color: "#a60364",
                        paddingTop: "6%"
                      }}
                      className="icon-down-arrow"
                    />
                  </a>
                </div>
              );
            }}
          </TranslationConsumer>
          <div>
            <Img image="image7" />
          </div>
        </div>
        <TranslationConsumer>
          {(context: any) => {
            return (
              <div id="cycle" style={{ paddingTop: "10%" }}>
                <h2>{context.translate("product2.productCycle")}</h2>
                <span style={{ paddingTop: "5%" }}>
                  {context.translate("product2.cycleText1")}
                  <br />
                  <br />
                  {context.translate("product2.cycleText2")}
                </span>
                <a href="#footer" onClick={this.navigateTo}>
                  <i
                    style={{
                      color: "#a60364",
                      paddingTop: "6%",
                      marginBottom: "0"
                    }}
                    className="icon-down-arrow "
                  />
                </a>
              </div>
            );
          }}
        </TranslationConsumer>
      </section>
    );
  }
}

export default Product2;
