import * as React from "react";
import styled from "styled-components";
import Img from "./image";
import { TranslationConsumer } from "../context/TranslationContext";
import "../styles/header.css";

const HeaderWrapper = styled.div`
  margin-top: 85px;
  margin-bottom: 1.45rem;
  overflow: hidden;
  position: relative;

  .logo-class {
    width: 50px;
    display: inline-block;
  }

  .header-content a {
    position: absolute;
    font-size: 2vw;
    bottom: 8%;
    left: 50%;
    font-weight: normal;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    font-family: "icomoon" !important;
    z-index: 10;
    outline: none;
    text-decoration: none;

    i {
      color: #fff;
      font-weight: 400;
      font-size: 3vw;
    }
  }
`;

export interface HeaderProps {}

export interface HeaderState {}

class Header extends React.Component<HeaderProps, HeaderState> {
  state = {};
  navigateTo = (e: React.SyntheticEvent<HTMLAnchorElement>) => {
    const { href } = e.currentTarget;
    e.preventDefault();
    window.scroll({
      top:
        (document.querySelector(
          href.slice(href.lastIndexOf("/") + 1)
        ) as HTMLElement).offsetTop - 88,
      behavior: "smooth"
    });
  };

  render() {
    return (
      <HeaderWrapper className="header-top">
        <div className="bg-layer" id="header">
          <div className="header-content">
            <TranslationConsumer>
              {(context: any) => {
                return (
                  <div>
                    <h1>{context.translate("header.slogan")}</h1>
                  </div>
                );
              }}
            </TranslationConsumer>

            <a href="#machinery" onClick={this.navigateTo}>
              <i className="icon-down-arrow" />
            </a>
          </div>
          <Img image="background" />
        </div>
      </HeaderWrapper>
    );
  }
}

export default Header;
