import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.app/gatsby-image
 * - `StaticQuery`: https://gatsby.app/staticquery
 */

const Image = (props: any) => (
  <StaticQuery
    query={graphql`
      query {
        image1: file(relativePath: { eq: "img1.png" }) {
          childImageSharp {
            fluid(maxWidth: 570, maxHeight: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image2: file(relativePath: { eq: "img2.png" }) {
          childImageSharp {
            fluid(maxWidth: 732, maxHeight: 524) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image3: file(relativePath: { eq: "img3.png" }) {
          childImageSharp {
            fluid(maxWidth: 610, maxHeight: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image4: file(relativePath: { eq: "img4.png" }) {
          childImageSharp {
            fluid(maxWidth: 1281, maxHeight: 451) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image5: file(relativePath: { eq: "img5.png" }) {
          childImageSharp {
            fluid(maxWidth: 640, maxHeight: 450) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image6: file(relativePath: { eq: "img6.png" }) {
          childImageSharp {
            fluid(maxWidth: 640, maxHeight: 450) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image7: file(relativePath: { eq: "img7.png" }) {
          childImageSharp {
            fluid(maxWidth: 475, maxHeight: 502) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        background: file(relativePath: { eq: "bg.png" }) {
          childImageSharp {
            fluid(maxWidth: 1920, maxHeight: 920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        logo: file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 54, maxHeight: 45) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Img
        style={{ padding: 0 }}
        imgStyle={props.style}
        fluid={data[props.image].childImageSharp.fluid}
      />
    )}
  />
);
export default Image;
