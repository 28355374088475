import * as React from "react";
import "../styles/about.css";
import AboutItem from "./aboutItem";
import { TranslationConsumer } from "../context/TranslationContext";

export interface AboutProps { }

export interface AboutState {
  rotate: boolean;
}

class About extends React.Component<AboutProps, AboutState> {
  state = {
    rotate: false
  };

  textRotate = (e: React.SyntheticEvent) => {
    e.preventDefault();
    this.setState({
      rotate: true
    });
  };

  onBlur = (e: React.SyntheticEvent) => {
    e.preventDefault();
    this.setState({
      rotate: false
    });
  };

  render() {
    return (
      <section className="about-main" id="mission">
        <div className="about-content">
          <TranslationConsumer>
            {(context: any) => {
              return (
                <>
                  <AboutItem
                    {...{
                      imgClassName: "about-img-1",
                      className: "bottle",
                      name: context.translate("aboutCompany.item1"),
                      text: context.translate("aboutCompany.item1Text"),
                      image: "image3"
                    }}
                  />
                  <div className="about-item-2">
                    <AboutItem
                      {...{
                        imgClassName: "img-about-2",
                        className: "img-4",
                        name: context.translate("aboutCompany.item2"),
                        text: context.translate("aboutCompany.item2Text"),
                        image: "image4"
                      }}
                    >
                      <AboutItem
                        {...{
                          imgClassName: "",
                          className: "img-5",
                          name: context.translate("aboutCompany.item3"),
                          text: context.translate("aboutCompany.item3Text"),
                          image: "image5"
                        }}
                      />
                      <AboutItem
                        {...{
                          imgClassName: "",
                          className: "img-6",
                          name: context.translate("aboutCompany.item4"),
                          text: context.translate("aboutCompany.item4Text"),
                          image: "image6"
                        }}
                      />
                    </AboutItem>
                  </div>
                </>
              );
            }}
          </TranslationConsumer>
        </div>
      </section>
    );
  }
}

export default About;