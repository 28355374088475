import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Header from "../components/header";
import Product1 from "../components/product1";
import Product2 from "../components/product2";
import About from "../components/aboutCompany";
import "../components/layout.css";
import "../styles/style.css";

const IndexPage = () => (
  <Layout>
    <SEO title="Universal Closures" keywords={[`universal`, `closures`, `closure`, `closures armenia`]} />
    <Header />
    <Product1 />
    <About />
    <Product2 />
  </Layout>
);

export default IndexPage;
